.product-details-action .btn-cart{
    padding: 1rem 0.1rem;
}
.details-filter-row label
{
    width: 100px !important;
}
.details-filter-row .select-custom
{
    width: 190px;
}

.table td{
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.readMoreButton{
    padding-left: 0px; 
    color: #c96;
}

.btn-product{
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 2px;

}

.btn-product+.btn-product{
        border-left: rem #c96 !important;
}

.btn-product:hover span{
        color: white;
}

.btn-product span
{
        color: #c96;
}

.btn-wishlist:before
{
    font-family: "molla";
    margin-right: .9rem;
}

.btn-wishlist
{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    line-height: 1;
    transition: all .35s ease;
        flex-grow: 1;
    flex-basis: auto;
        margin: 2px;
    padding: 1rem 0.1rem;
}

.btn-wishlist span
{
    font-size: 1.4rem;
    color: inherit;
    text-transform: none;
    box-shadow: none;
    transition: box-shadow .35s ease, color 0s ease;
}

.videoContainer video 
{
  width: 100% !important;
  object-fit: fill;
}
.custome_select_box {
  /* z-index:1000; */
}
.hidden {
  display: none;
}

.slick-slider {
  width: 100% !important;
}

.btn-catalouge {
  opacity: 0;
}

.banner-link-anim:hover .btn-catalouge {
  opacity: 1;
}

.custom-banner-button:hover {
  color: white !important;
}

.banner-display > a:before {
  visibility: visible;
  opacity: 1;
}

.card-custom {
  margin-top: 0px !important;
}

.custome-search-container {
  text-align: center;
  width: 100%;
  max-width: 100%;
  background-color: rgba(202, 150, 98, 0.55);
  padding: 15px;
  padding-left: 10%;
  padding-right: 10%;
}

.custome-search-button {
  color: #fff !important;
  background-color: #cc9966 !important;
  border-color: #cc9966 !important;
  box-shadow: none !important;
}

.custome-product-search-container {
  text-align: center;
  width: 100%;
  max-width: 100%;
  background-color: rgba(202, 150, 98, 0.55);
  padding: 15px;
  padding-left: 10%;
  padding-right: 10%;
}

.custom-control.custom-radio .custom-control-label::before {
  top: 1.2rem !important;
}

.custom-control.custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  top: 1.7rem !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .custom-control-label {
    padding-left: 5px !important;
  }

  .custom-control-label-mobile {
    padding-left: 6% !important;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 736px) and (orientation: landscape) {
  .custom-control-label-mobile {
    padding-left: 6% !important;
  }

  .custome-search-button {
    margin-top: 2%;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .custom-control-label-mobile {
    padding-left: 6% !important;
  }

  .custome-search-button {
    margin-top: 2%;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .custom-control-label-mobile {
    padding-left: 6% !important;
  }

  .custome-search-button {
    margin-top: 2%;
  }
}

input[type="number"]:hover::-webkit-inner-spin-button {
  height: 24px !important;
  z-index: 99999 !important;
}

.custom-text_extra-services_quantity {
  height: 24px !important;
}

.section-b-space {
  padding-top: 70px;
}

.custome-footer-color {
  color: rgb(183, 130, 80);
}

.custome-footer-color:hover {
  color: rgb(183, 130, 80);
}

.padding-page-boxes {
  border: 1px solid;
  text-align: center;
  height: 50px;
  border-right: 0px !important;
  padding-top: 0.7%;
}

.landing-page-box-text {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}

.landing-page-box-a {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}
.margin {
  margin-left: 58px !important;
}
.font-12 {
  font-size: 16px;
}
.radio-margin {
  margin-top: 5px;
}
.landing-page-slider-box {
  padding-right: 1px;
  padding-left: 1px;
  margin-right: 0px;
  margin-left: 0px;
  height: 100%;
  text-align: center;
  padding-top: 1px;
}
.font-class {
  font-size: 10px;
  font-weight: 700;
}
.font-class-cutouts {
  font-size: 11px;
  font-weight: 700;
}
.font-class-extra-service {
  font-size: 12px;
}
.font-class-design {
  font-size: 11px;
}
.padding-class {
  padding-left: 15px;
}
.panel-font {
  font-size: 10px;
  font-weight: 700;
}
.padding-class2 {
  padding-left: 23px;
  font-size: 10px;
  font-weight: 700;
}

.custome_text_field_edit_quote {
  margin-top: 6%;
  height: 23px !important;
}

.custome_table_add_sku {
  /* border-bottom: 1px solid #c96;
  margin-bottom: 2%; */
  /* margin-left: 0.5px; */
}
.custome_table_get_a_quote {
  border: 1px solid black;
  padding-left: 0px;
  padding-right: 0px;
}
.video-slider-custome-landing-page {
  height: 50% !important;
  position: absolute !important;
  top: 23%;
  left: 0;
  width: 100%;
  padding: 0 0;
  text-align: left;
  z-index: 1;
  /* border-width: 1px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-radius: 0px;
  background-color: rgba(255, 255, 255, 0.5); */
}

.landing-page-top-image {
  max-height: 100%;
  width: 100%;
}

.contact_border {
  border: 1px solid black !important;
  display: table !important;
  width: 100% !important;
  min-height: 200px !important;
  text-align: center !important;
  height: 210px !important;
  padding: inherit !important;
  font-size: large !important;
  margin-right: -1px !important;
  margin-bottom: 12px !important;
}
.contact_paragraphs {
  font-size: 20px !important;
  display: table-cell !important;
  vertical-align: middle !important;
  font-weight: bold !important;
}
.contact_paragraphs_up {
  font-weight: bold !important;
  font-size: 16px !important;
  padding-top: 6px !important;
  vertical-align: middle !important;
}
.contact_paragraphs_first {
  padding-top: 30px !important;
}
.section-pad {
  padding-bottom: 3px;
  padding-top: 12px;
}
.heading-contact {
  font-weight: "bold" !important;
  font-size: "12px" !important;
}
.contact-map {
  border: 1px solid black !important;
  margin-right: -1px !important;
  padding-bottom: 18px !important;
  padding-top: 10px !important;
}
.pad {
  padding-bottom: 10px !important;
}
.fa-twitter:before {
  font-size: 30px;
}
.fa-facebook-f:before,
.fa-facebook:before {
  font-size: 30px !important;
}
p {
  color: #000 !important;
}
.fa-instagram:before {
  font-size: 30px;
}
.fa-pinterest:before {
  font-size: 30px;
}
.link {
  color: #059;
}
.book-appointment-form {
  left: 50%;
  top: 50%;
  margin-left: -25%;
  margin-top: 0%;
}
.inline {
  display: inline;
}
.theme-form input {
  /* border: 1px solid #000000a6 !important; */
}
.theme-form textarea {
  border: 1px solid #000000a6 !important;
}
.section-b-space-customer-details {
  padding-top: 90px;
}
.customer-select {
  border: 1px solid !important;
}
.custome_lable {
  display: inline-block;
  font-size: 10px;
  margin-bottom: 0.5rem;
  position: relative !important;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
}
.custome_lable_fabrication {
  display: inline-block;
  font-size: 10px;
  margin-bottom: 0.5rem;
  margin-left: 13px;
  position: relative !important;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
}
.custome_lable_length {
  display: inline-block;
  font-size: 10px;
  margin-bottom: 0.5rem;
  position: relative !important;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
}
/* .custome_lable_pieces {
  display: inline-block;
  font-size: 10px;
  margin-left: 10px;
  margin-bottom: 0.5rem;
  position: relative !important;
} */
.custome_lable_area {
  display: inline-block;
  font-size: 10px;
  margin-left: 5px;
  margin-bottom: 0.5rem;
  position: relative !important;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
}
.custome_lable_pieces {
  display: inline-block;
  font-size: 10px;
  margin-left: 1%;
  margin-bottom: 0.5rem;
  position: relative !important;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
}
.custome_lable_shaped {
  display: inline-block;
  font-size: 10px;
  margin-left: 27px;
  margin-bottom: 0.5rem;
  position: relative !important;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
}

.custome_lable_accept {
  display: inline-block;

  margin-bottom: 0.5rem;
  position: relative !important;
}
.custome_lable_lenght {
  display: inline-block;
  font-size: 10px;
  margin-left: 15px;
  margin-bottom: 0.5rem;
  position: relative !important;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
}
.custome_lable_extra {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 10px;
  position: relative !important;
  margin-left: 15px;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
}
.cddd {
  font-size: 11px;
  font-weight: 700;
  color: rgb(255, 255, 255);
}
.srch-frm {
  margin-top: 50px !important;
  display: inline-flex;
}
.cntr {
  margin-right: 30px;
}
.btn-p {
  background-color: #253746;
  border-color: #253746;
}
.footer-color {
  background-color: #253746 !important;
}
.custome-foroter-color {
  color: rgb(183, 130, 80);
}
.section-b-space_l {
  padding-top: 7px !important;
}
.header-filter {
  margin: 20px auto;
}
.col-sm-2 {
  flex: 0 0 16.66667%;
  max-width: 13.66667%;
}
.costume {
  margin-right: -80px;
}
.sec-b-spc {
  margin-top: 0px;
}

@media (min-width: 1200px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1,
  .col-md-3 {
    width: 14.285714285714285714285714285714% !important;
    width: 16.285714285714285714285714285714% !important;
    max-width: 16% !important;
    flex: 0 0 16.33333% !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}

@media (min-width: 992px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1,
  .col-md-3 {
    width: 14.285714285714285714285714285714% !important;
    width: 16.285714285714285714285714285714% !important;
    max-width: 16% !important;
    flex: 0 0 16.33333% !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}
.custom-span {
  font-size: 12px;
  font-weight: 700;
  color: #333333;
}
.img-thumbnails {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 30%;
}
.btc {
  background-color: rgba(37, 55, 70, 1);
  border-color: rgba(37, 55, 70, 1);
  color: white;
  font-size: 14px;
  border-radius: 4px !important;
}
.down {
  padding-bottom: 70px;
}
.cust-marg {
  padding-left: 35%;
}
.custome_lable_n {
  display: inline-block;
  margin-bottom: 0.5rem;
  position: relative !important;
}
.cust-span {
  font-size: 12px;
  color: rgb(183, 130, 80);
}
.custom-span-get-quote {
  margin-left: -11px;
}
.Red-delete-Panel-button {
  border-width: 0px;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 25px;
  background: inherit;
  border: 1px solid red !important;
  color: red;
  border: none;
  border-radius: 5px;
}

.Red-delete-Panel-button:hover {
  background-color: red !important;
  color: white;
}

.Green-Add-Panel_button {
  border-width: 0px;
  margin-right: 1px;
  margin-left: 20px;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 25px;
  margin-top: 0px;
  background: inherit;
  border: 1px solid green !important;
  color: green;
  border: none;
  border-radius: 5px;
}
.Green-Add-Panel_button_Glass {
  border-width: 0px;
  margin-right: 1px;
  margin-left: 32px;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 25px;
  margin-top: 0px;
  background: inherit;
  border: 1px solid green !important;
  color: green;
  border: none;
  border-radius: 5px;
}
.Green-Add-Panel_button:hover {
  background-color: green !important;
  color: white;
}
.Green-Add-Panel_button_Glass:hover {
  background-color: green !important;
  color: white;
}
.break {
  flex-basis: 100%;
  height: 0;
  border: 0.5px solid black;
}
.break2 {
  flex-basis: 100%;
  height: 0;
  border: 0.5px solid black;
  margin-right: 13px;
}
.Custom-color {
  font-family: Ubuntu-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #c96;
  margin: 0;
}
.Custom-color:hover {
  color: white;
}
.cutome-rounded-button:hover .Custom-color {
  color: white !important;
}

.cutome-rounded-delete-button {
  min-width: 25px !important;
  border-radius: 3rem !important;
  padding: 0px !important;
}
.react-confirm-alert-body > h1 {
  font-size: 23px !important;
}

.cutome-rounded-history-button {
  min-width: 77px;
  border-radius: 3rem !important;
  padding: 0%;
  font-size: 12px;
  text-transform: capitalize;
}

.grey_color {
  background-color: rgba(242, 242, 242, 1);
}
.custo {
  margin-left: 50%;
}
.custo2 {
  margin-left: 21%;
  left: 0px;
  top: 0px;
  margin-left: 36px;
  width: 50px;
  height: 20px;
  font-family: "ArialMT", "Arial";
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-decoration: none;
  font-size: 12px;
  color: #000000;
  text-align: left;
}
.custom-text_fabrication {
  left: 0px;
  top: 0px;
  width: 53px;
  height: 20px;
  font-family: "ArialMT", "Arial";
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  text-decoration: none;
  color: rgb(153, 153, 153);
  text-align: left;
  padding-left: 3px;
  padding-right: 3px;
}
.cust-marg-get-a-quote {
  margin-left: -3%;
}
.Designtable {
  display: table;
  float: left;
  margin-right: 15px;
}
.lebel {
  display: table-row;
}
.chk {
  display: table-row;
  margin-left: 15px;
}
.cust-center {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.cust-centers {
  vertical-align: middle;
  line-height: normal;
}
.cusdiv {
  text-align: center;
}
.link-color-c {
  color: rgb(183, 130, 80) !important;
}
header .main-navbar .nav-menu > li:hover > a {
  color: #b78250 !important;
}
header .main-navbar .nav-menu > li .nav-submenu li a:before {
  background-color: #b78250 !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-left: 75px;
}

.demopadding {
  margin: 50px auto;
  width: 140px;
  text-align: center;
}
.icon {
  position: relative;
  text-align: center;
  width: 0px;
  height: 0px;
  padding: 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  -khtml-border-radius: 20px 20px 20px 20px;
  color: #ffffff;
}
.icon i {
  font-size: 20px;
  position: absolute;
  left: 9px;
  top: 10px;
}
.icon.social {
  float: left;
  margin: 0 5px 0 0;
  cursor: pointer;
  background: white;
  color: #262626;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
.icon.social:hover {
  background: #262626;
  color: #6d6e71;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.8));
  -moz-filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.8));
  -ms-filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.8));
  -o-filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.8));
  filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.8));
}
.icon.social.fb i {
  left: 11px;
  top: 7px;
}
.icon.social.tw i {
  left: 6px;
  top: 4px;
}
.icon.social.in i {
  left: 8px;
  top: 5px;
}
.icon.social.pi i {
  left: 6px;
  top: 5px;
}
.icon.social.hz i {
  left: 13px;
  top: 8px;
  font-size: x-large;
}
.custom-text {
  left: 0px;
  top: 0px;
  width: 75px;
  height: 20px;
  font-family: "ArialMT", "Arial";
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  text-decoration: none;
  color: #000000;
  text-align: left;
}
.custom-text_panel {
  left: 0px;
  top: 0px;
  width: 65px;
  height: 20px;
  font-family: "ArialMT", "Arial";
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-decoration: none;
  color: #000000;
  text-align: left;
  font-size: 12px;

  padding: 1px 0px 1px 0px;
  box-sizing: border-box;
}
.custom-text_panel_Pieces {
  left: 0px;
  top: 0px;
  width: 44px;
  margin-left: 2px;
  height: 20px;
  font-family: "ArialMT", "Arial";
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-decoration: none;
  color: #000000;
  text-align: left;
  font-size: 12px;
  padding: 1px 0px 1px 0px;
  box-sizing: border-box;
}
.custom-text_panel_pieces {
  left: 0px;
  top: 0px;
  width: 65px;
  height: 20px;
  margin-left: 13px;
  font-family: "ArialMT", "Arial";
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-decoration: none;
  color: #000000;
  text-align: left;
  font-size: 12px;
  color: rgb(153, 153, 153);
  padding: 1px 0px 1px 0px;
  box-sizing: border-box;
}
.custom-text_extra-services_quantity {
  margin-left: 14px;
  left: 0px;
  top: 0px;
  width: 45px;
  height: 20px;
  font-family: "Helvetica";
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  text-decoration: none;
  color: #000000;
  text-align: left;
}
.span-of-area {
  margin-left: 41px;
  left: 0px;
  top: 0px;
  width: 45px;
  height: 20px;
  font-family: "Helvetica";
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  text-decoration: none;
  color: #000000;
  text-align: left;
}
.custom-text_cutouts {
  left: 0px;
  top: 0px;
  width: 52px;
  height: 20px;
  font-family: "ArialMT", "Arial";
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  text-decoration: none;
  color: #000000;
  text-align: left;
}
.img-wrapper:hover {
  opacity: 0.9 !important;
}
.head-clr {
  background-color: rgba(139, 172, 69, 1);
  max-width: 111%;
}
.head-clr2 {
  background-color: rgba(139, 172, 69, 1);
  max-width: 100%;
  border: 1px solid rgba(139, 172, 69, 1);
}
.head-clr3 {
  max-width: 100%;
  background-color: rgba(235, 235, 235, 1);
}
.head-clr4 {
  border: 1px solid #c96;
  margin-left: 10px;
  margin-right: 10px;
}
.border-matt {
  border-bottom: 1px solid rgba(235, 235, 235, 1);
  padding-left: 10px;
}
.header-stripe {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1290px;
  height: 25px;
  background: inherit;
  background-color: rgba(183, 130, 80, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(121, 121, 121, 1);
  border-radius: 0px;
  box-shadow: none;
}
.stripe-align {
  align-content: left;
  font-size: 12px;
  color: rgb(255, 255, 255) !important ;
}
.components-align {
  font-size: 14px;
  color: rgb(255, 255, 255) !important ;
  text-align: right;
}
.spn-cls {
  color: #fff;
}
.spn-cls:hover {
  color: #fff;
}
.finish-labels {
  text-align: left;
  margin-left: -44px;
}
/* image picker css */
.fileInput {
  border-bottom: 4px solid lightgray;
  border-right: 4px solid lightgray;
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
  margin: 15px;
  cursor: pointer;
  max-width: 56%;
}
.imgPreview {
  text-align: center;
  margin: 5px 15px;
  height: 287px;
  width: 56%;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 5px solid gray;
  border-bottom: 5px solid gray;
}
.img {
  width: 100%;
  height: 100%;
}
.previewText {
  width: 100%;
  margin-top: 20px;
}
.submitButton {
  padding: 12px;
  margin-left: 10px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
}
.centerText {
  text-align: center;
  width: 500px;
}

.cust-img-cap {
  max-width: 56%;
  margin-left: 14px;
}
.custome_table_add_sku {
  /* border-bottom: 1px solid #c96;
  margin-bottom: 2%; */
}
.sidebar-wrapper {
  max-width: 17% !important
  ;
}
.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 3px 30px 0px 21px;
}
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand > a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer > a {
  color: white;
}
.cutom_button_get_quote {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 40%;
  width: 15px;
  height: 19px;
  background: inherit;
  background-color: rgba(139, 172, 69, 1);
  border: none;
  border-radius: 5px;
}

.custome-select-box-sku {
  align-items: center;
  background-color: hsl(0, 0%, 98%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 27px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  width: 100%;
  font-size: 1.3rem;
}
.custom-control-label {
  font-size: 1.3rem;
  color: black;
}

.custom-control-input:disabled ~ .custom-control-label {
  font-size: 1.3rem;
  color: black;
}

.custom-control-text {
  font-size: 1.3rem;
  color: black;
  padding-left: 5px;
}

.custom-control-text:focus {
  font-size: 1.3rem;
  color: black;
  padding-left: 5px;
}

.custome_select_metrial_type {
  font-size: 1.2rem !important;
  font-weight: normal !important;
}

.form-control:focus {
  font-size: 1.3rem;
  color: black;
  padding-left: 5px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #c96 !important;
  background-color: #c96 !important;
}

.para-margins {
  margin-left: 40px;
  margin-top: 5px;
}
.Image-div {
  left: 19px;
  top: 23px;
  width: 93px;
  height: 61px;
}
.side-Info {
  border-width: 0px;
  position: absolute;
  right: 17px;
  top: 96px;
  width: 175px;
  height: 47px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 0px;
}
.para {
  font-size: 12px;
  text-align: left;
}
.pdf-footer {
  top: 2050px;
  width: 923px;
  height: 58px;
}
.pdf-footer2 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 923px;
  height: 22px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(121, 121, 121, 1);
  border-radius: 0px;
}
.TextC {
  top: 2px;
  width: 919px;
}
.pdf-footer-text {
  font-size: 11px;
  text-align: left;
}
.custome-select-box-get-a-quote {
  align-items: center;
  background-color: hsl(0, 0%, 98%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: inline-flex;
  /* -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; */
  min-height: 23px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  width: 100%;
}
.radio-inline1 {
  margin-left: -14px;
}
.side-survey {
  margin-left: -44px;
}
.select-box-survey-fit {
  align-items: center;
  background-color: hsl(0, 0%, 98%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: inline-flex;
  min-height: 23px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  width: 114px;
}

/* PDF CSS */
/* div#root {
  margin: 0;
  padding: 0;
}

div#root {
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

section.pdf-body {
  padding: 16px;
  background-color: rgb(238, 238, 238);
  height: 100%;
  margin: 8px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex {
  flex: 1 1 auto;
}

input,
textarea {
  border: 1px solid rgb(37, 22, 22);
  border-radius: 3px;
  padding: 8px;
  margin: 4px;
} */
.Button-color-SKU {
  background-color: rgb(183, 130, 80);
  border: rgb(183, 130, 80);
}
.Button-color-SKU:hover {
  background-color: rgb(183, 130, 80);
  border: rgb(183, 130, 80);
}
.skulist-padding-class {
  margin-left: 10px;
}
.table-down {
  margin-top: 50px;
}
.pdf-section {
  display: none;
}
.print-only {
  display: none;
}
@media print {
  .media-body {
    color: red !important;
  }
  .noprint {
    display: none;
  }
  .pdfLabel {
    font-size: 10px;
  }
  .print-only {
    color: #059;
    display: block !important;
  }
}

.image-upload-custome {
  border-bottom: 4px solid lightgray;
  border-right: 4px solid lightgray;
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
  margin: 15px;
  cursor: pointer;
}

.image-preview-custome {
  text-align: center;
  margin: 5px 15px;
  height: 287px;
  width: 56%;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 5px solid gray;
  border-bottom: 5px solid gray;
}
.productName {
  font-size: 14px;
  color: rgb(37, 55, 70) !important;
}
.ClassicEditor {
  margin-left: 252px;
}
.bt-cust {
  width: 124px;
  height: 22px;
  font-size: 12px;
  background-color: rgba(37, 55, 70, 1);
  border-color: rgba(37, 55, 70, 1);
  color: white;
  line-height: 14px !important;
  border-radius: 4px !important;
}
.bt-orange {
  width: 167px;
  height: 22px;
  font-size: 12px;
  background-color: rgba(183, 130, 80, 1);
  border-color: none;
  color: white;
  line-height: 14px !important;
  border-radius: 4px !important;
}
.bt-green {
  width: 88px;
  height: 22px;
  background: inherit;
  background-color: rgba(153, 204, 153, 1);
  border: none;
  border-radius: 4px !important;
  box-shadow: none;
  color: #000000;
  line-height: 14px !important;
}
.facts-sheet {
  left: 727px;
  top: 409px;
  width: 424px;
  height: 208px;
  float: right;
}
.facts-inner {
  left: 0px;
  top: 0px;
  width: 424px;
  height: 208px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(121, 121, 121, 1);
  border-radius: 0px;
}
.facts-heading {
  border-width: 0px;
  left: 0px;
  top: 0px;
  width: 424px;
  height: 28px;
  background: inherit;
  background-color: rgba(37, 55, 70, 1);
  border: none;
  border-radius: 0px;
}
.fact-para {
  font-weight: 700;
  color: rgb(255, 255, 255) !important;
  font-size: 13px;
  text-align: left;
  line-height: 2;
  padding-left: 7px;
}
.priceBand {
  font-size: 12px;
  font-weight: 700;
  color: rgb(37, 55, 70) !important;
  padding-left: 4px;
}
.innerBand {
  font-size: 12px;
  color: rgb(37, 55, 70) !important;
  margin-left: 10px;
}
.tileSizeClass {
  border-width: 0px;
  left: 0px;
  top: 0px;
  width: 400px;
  height: 43px;
  background: inherit;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
}
.slabSizeClass {
  border-width: 0px;
  left: 0px;
  top: 0px;
  width: 400px;
  height: 58px;
  background: inherit;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.priceBandManufacturer {
  font-size: 12px;
  font-weight: 700;
  margin-left: 50px;
  color: rgb(37, 55, 70) !important;
}
.ck-editor__editable_inline {
  min-height: 200px !important;
}

.header_color {
  background: rgba(121, 121, 121, 1);
}

.quote_table_border {
  border: 1px solid black;
}

.sky_th_color {
  border: 1px solid black !important;
  color: white !important;
}

.quote_table_border th {
  border: 1px solid black !important;
  vertical-align: middle;
}

.quote_table_border td {
  border: 1px solid black !important;
  vertical-align: middle;
}

.sku_img_thumbnails {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  width: 70px;
  height: 70px;
}

.w-25 {
  text-align: center !important;
}

.remove_item_sku {
  text-align: center !important;
}

.delete-row {
  min-width: 30px !important;
  padding: 1px 8px !important;
}

.first_plus_button {
  margin-top: 2.4rem !important;
}

.disabled_text_field {
  background-color: #fff !important;
  border: none !important;
}

.version_button {
  height: 15px !important;
  display: inline-block;
  background-color: #1984c3;
  color: #fff;
  font-size: 9px;
  font-weight: bold;
  font-family: Courier, sans-serif;
  padding: 0em 0.3em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0.5em;
  /* border-bottom: 0.25em solid #0f5177; */
  transition: 0.1s;
  box-shadow: 0 2px 3px #ccc;
  position: inherit;
}

.version_button:hover {
  background-color: #1e9feb;
  border-bottom-width: 0;
  margin-top: 0.2em;
}

.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  -webkit-animation: load8 1.1s infinite linear;
          animation: load8 1.1s infinite linear;
}

.quote-loader {
  position: absolute;
  top: calc(50% - 0em);
  left: calc(50% - -90%);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  -webkit-animation: load8 1.1s infinite linear;
          animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.communication_area:focus {
  border: 1px solid black !important;
}

.about-text p {
  margin-bottom: 2px !important;
  line-height: 25px !important;
}

.light-layout {
  padding: 34px 0 20px 0 !important;
}

.footer-social {
  margin-top: 9px !important;
  margin: 4px auto !important;
  width: 100% !important;
}

.light-layout {
  padding: 12px 0 10px 0 !important;
}

/* hide left and right menu */
.sidenav {
  display: none !important;
}

.navbar {
  display: none !important;
}

.mobile-search {
  display: none !important;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart {
  display: none !important;
}

.slide_img {
  height: 250px;
  max-height: 250px;
}

.carousel-slider {
  height: 250px;
  max-height: 250px;
}

.slide_txt {
  background-color: #343a409e !important;
  color: #fff !important;
  font-weight: bold !important;
  opacity: 1 !important;
}

.card {
  margin-left: 1% !important;
  margin-top: 0% !important;
}

.product_image {
  min-width: 205px;
  min-height: 270px;
  width: 205px;
  height: 270px;
  max-width: 205px;
  max-height: 270px;
}

.Component-paragraphs {
  line-height: 1.1rem;
}

.custome-slider {
  height: 100% !important;
}

.custome-product-slider {
  height: auto !important;
  margin-bottom: 4%;
}

.slider-video {
  width: 101%;
}
.slick-slider .slick-track .slick-initialized .slick-slide {
  width: 100% !important;
}

.about_text {
  font-size: 17px !important;
  margin-bottom: 2% !important;
}

.home {
  max-width: 100% !important;
}

header .main-navbar .nav-menu > li .nav-submenu li a {
  padding: 5px 10px !important;
}

.carousel-container
  .react-multi-carousel-track
  .react-multi-carousel-item--active {
  background-color: #00aec7;
  padding: 1px;
  margin: 3px;
}

.carousel-container .react-multi-carousel-track .project-coursel-span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  background-color: #6b8d98a6;
}

.carousel-container
  .react-multi-carousel-track
  .react-multi-carousel-item--active
  .project-coursel-span {
  opacity: 0;
}

.project-image-custome {
  height: 400px !important;
}

.btn-primary:hover {
  background-color: rgb(183, 130, 80) !important;
  border-color: rgb(183, 130, 80) !important;
}

.ft-10 {
  font-size: 10px;
}

.table-border-right-left-bottom-none {
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 0px !important;
  color: rgb(183, 130, 80) !important;
}

.table-border-left-bottom-none {
  border-left: 0px !important;
  border-bottom: 0px !important;
  color: #253746 !important;
}

.container-past-project-full-width {
  background-color: #f3f3f3 !important;
  max-width: 100% !important;
  padding: 0px;
}

.blog-image {
  height: 300px;
  width: 400px;
  max-height: 300px;
  max-width: 400px;
}

.gold_text_blog {
  color: rgb(183, 130, 80) !important;
}

.blog-image-custome {
  width: 1370px;
  height: 385px;
}

.custome_select_box {
  text-align: left !important;
}

.custome_select_box__placeholder {
  font-size: 10px !important ;
}

.product-table-edit-quote th,
.product-table-edit-quote td {
  border: 1px solid black !important ;
  color: #212529;
}

.table_product_subtotal {
  width: 73% !important;
}

.product_sub_total_border {
  border: 1px solid black;
  font-size: 11px;
}

.product_sub_total_padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.product_total {
  color: #fff;
  background-color: #c96;
  /* background-color: #6fb22f; */
}

.product-table-edit-quote {
  margin-bottom: 0.1rem;
}

.glass-category-box {
  border-bottom: 1px solid rgb(183, 130, 80) !important;
  padding: 4%;
}

/* .glass-category-box:hover
{
  border: 1px solid rgb(183, 130, 80) !important;
} */

.category-detail-button {
  float: right;
  background: black;
  color: #fff;
  padding: 2%;
}

.mobile-container {
  width: 97%;
  max-width: 97%;
  margin-left: 0%;
  margin-right: 0%;
}

.custome_lable_mobile {
  display: inline-block;
  font-size: 8px !important;
  margin-bottom: 0.5rem;
  position: relative !important;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
}

.custom_text_panel_mobile {
  width: 55px !important;
  padding: 0px;
}

.custom_text_panel_mobile_Pieces {
  width: 55px !important;
}

.custome_lable_pieces_mobile {
  display: inline-block;
  font-size: 8px !important;
  margin-left: 1%;
  margin-bottom: 0.5rem;
  position: relative !important;
  font-size: 10px;
  font-weight: 700;
  color: #000000;
}

.break_mobile {
  flex-basis: 100%;
  height: 0;
  border: 0.5px solid black;
  margin: 1rem auto 0.5rem !important;
}

.font-class-design-mobile {
  color: black;
  font-weight: bold;
}

.panel-font-mobile {
  font-size: 11px;
  color: black;
  font-weight: bold;
}

.padding-class2-mobile {
  font-size: 11px;
  color: black;
  font-weight: bold;
}

.font-class-mobile {
  font-size: 11px;
  color: black;
  font-weight: bold;
}

.font-class-cutouts-mobile {
  font-size: 11px;
  color: black;
  font-weight: bold;
}

.font-class-extra-service-mobile {
  font-size: 11px;
  color: black;
  font-weight: bold;
}

.page-content {
  padding-bottom: 2rem !important;
}

.table {
  margin-bottom: 2rem !important;
}

.Red-delete-Panel-button-mobile {
  border-width: 0px;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 25px;
  background: inherit;
  border: 1px solid red !important;
  color: red;
  border: none;
  border-radius: 10px;
}

.Red-delete-Panel-button-mobile:hover {
  background-color: 1px solid red !important;
  color: white;
}

.Green-Add-Panel_button-mobile {
  border-width: 0px;
  margin-right: 1px;
  margin-left: 5px;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 25px;
  margin-top: 0px;
  background: inherit;

  border: none;
  border-radius: 10px;
}
.Green-Add-Panel_button_Glass-mobile {
  border-width: 0px;
  margin-right: 1px;
  margin-left: 32px;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 25px;
  margin-top: 0px;
  background: inherit;
  border: 1px solid green !important;
  color: green;
  border: none;
  border-radius: 5px;
}

.Green-Add-Panel_button_Glass-mobile:hover {
  background-color: green !important;
  color: white;
}

.form-group-mobile {
  margin-bottom: 1rem !important;
}

.custome_cutout_margin_get_a_quote {
  margin-left: 48% !important;
  margin-bottom: 0.5rem !important;
  position: relative !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  color: #000000 !important;
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) and (orientation: landscape) {
  .custome-lable-for-quote-mobile {
    width: 32% !important;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) and (orientation: portrait) {
  .custome-lable-for-quote-mobile {
    width: 30% !important;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .custome-lable-for-quote-mobile {
    width: 30% !important;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .custome-lable-for-quote-mobile {
    width: 25% !important;
  }

  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .custome-lable-for-quote-mobile {
      width: 25% !important;
    }
  }
}


* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}
.ch_hight {
  height: 100%;
}
.ch_button_handel {
  text-align: center !important;
  width: 40%;
  color: white !important;
}
.ch_button_handel a {
  color: white !important;
}
.HANDICRAFTS_h br {
  display: none !important;
}
#bespoke-main {
  width: 100%;
  height: 90vh;
  background-image: linear-gradient(
      to bottom,
      rgba(3, 44, 71, 0.52),
      rgba(3, 45, 56, 0.73)
    ),
    url("/assets/images/custom_pages/glass-splash-back/bannerimage.jpg");

  background-size: 100% 100%;
  border: 1px solid yellow;
}
/* .inner-bespoke {
  width: 115%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  color: white;
  display: flex;
  justify-content: center;
  align-items: end;
} */
.bespoke-inner-glass-text {
  height: 50%;
  width: 70%;
  border: 1px solid rgb(0, 0, 0);
  position: relative;
  top: 17%;
  left: 5%;
  color: white !important;
}
.bespoke-inner-text {
  width: 58%;
  height: 80%;
  border: 1px solid red;
  color: white !important;
}
#about-us {
  width: 100%;
  height: 90vh;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fill-head {
  text-align: start !important;
  color: white !important;
}
.contact-map-selected {
  text-align: start;
}
.about-us-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  height: 80%;
  width: 50%;
  /* border: 1px solid black; */
}
.what-make-para {
  width: 70%;
  height: 70%;
  border: 1px solid black;
}
.glass-para {
  width: 90%;
  border: 1px solid black;
}
.about-us-image {
  height: 80%;
  width: 50%;
  /* border: 1px solid black; */
}
.grid-container {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  /* padding: 5px; */
}
.grid-item {
  border: 1px solid black;
}
.grid-item1 {
  border: 1px solid white;
  grid-column-start: 1;
  grid-column-end: 1;
  background-image: url("/assets/images/gnf/Who_we_are_Image1.png");
  background-size: 100% 100%;
}
.grid-item2 {
  border: 1px solid white;
  grid-column-start: 2;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  background-image: url("/assets/images/gnf/Who_we_are_Image3.png");
  background-size: 100% 100%;
}
.grid-item3 {
  border: 1px solid white;
  grid-column-start: 2;
  grid-column-end: 1;
  background-image: url("/assets/images/gnf/Who_we_are_Image2.png");
  background-size: 100% 100%;
}

#glass-range {
  height: 75vh;
  border: 1px solid green;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.glass-grid-container {
  height: 90%;
  width: 90%;
  display: grid;
  grid-gap: 10px;
  border: 1px solid black;
}
.item-img1 {
  border: 1px solid orange;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_plain.png");
  background-size: 100% 100%;
}
.item-img2 {
  border: 1px solid orange;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_crackled_glass.png");
  background-size: 100% 100%;
}
.item-img3 {
  border: 1px solid orange;
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_digital_print.png");
  background-size: 100% 100%;
}
.item-img4 {
  border: 1px solid orange;
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_double_layer.png");
  background-size: 100% 100%;
}
.item-img5 {
  border: 1px solid orange;
  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_mettalic_effect.png");
  background-size: 100% 100%;
}
.item-img6 {
  border: 1px solid orange;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_plain_sparkle.png");
  background-size: 100% 100%;
}
.item-img7 {
  border: 1px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_shimmer_effect.png");
  background-size: 100% 100%;
}
.item-img8 {
  border: 1px solid orange;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_special_effect.png");
  background-size: 100% 100%;
}
.item-img9 {
  border: 1px solid orange;
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_satin_glass.png");
  background-size: 100% 100%;
}
.item-img10 {
  border: 1px solid orange;
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_toughened_mirrors.png");
  background-size: 100% 100%;
}
.item-img11 {
  border: 1px solid orange;
  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_Untoughened_mirrors.png");
  background-size: 100% 100%;
}
#what-makes {
  width: 100%;
  height: 90vh;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.what-make-grid-container {
  display: grid;
  grid-column-gap: 10px;
  /* padding: 5px; */
  grid-gap: 10px;
}
.item-what-img1 {
  border: 1px solid orange;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  background-color: #243746 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.item-what-img2 {
  border: 1px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-color: #243746 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.item-what-img3 {
  border: 1px solid orange;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: #243746 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.item-what-img4 {
  border: 1px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
  background-color: #243746 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.item-what-inner-content {
  color: #b68150;
  /* position: relative;
  left: 10%; */
}
#top-trending {
  width: 100%;
  height: 35vh;
  border: 1px solid black;
  background-color: rgb(230, 230, 230);
  display: flex;
  justify-content: center;
}
.top-trending-text {
  width: 40%;
  height: 100%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-trending-img {
  width: 40%;
  height: 100%;
  border: 1px solid black;
  display: grid;
  grid-column-gap: 10px;
}
.trending-1 {
  border: 1px solid orange;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/GnF Glass Splashback Works - 2.jpg");
  background-size: 100% 100%;
}
.trending-2 {
  border: 1px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/GnF Glass Splashback Works - 3.jpg");
  background-size: 100% 100%;
}
.trending-3 {
  border: 1px solid orange;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/GnF Glass Splashback Works - 4.jpg");
  background-size: 100% 100%;
}
#make-it-happen {
  width: 100%;
  height: 50vh;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.happen-img {
  width: 70%;
  height: 80%;
  border: 1px solid black;
  display: grid;
  grid-column-gap: 10px;
}
.happen-img-1 {
  border: 1px solid orange;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/3.jpg");
  background-size: 100% 100%;
}
.happen-img-2 {
  border: 1px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/fabrication-2.jpg");
  background-size: 100% 100%;
}
.happen-img-3 {
  border: 1px solid orange;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/installation.jpg");
  background-size: 100% 100%;
}
#over-400 {
  width: 100%;
  height: 60vh;
  background-image: url("/assets/images/gnf/bespoke/Over_4000_installation_BG.png");
  background-size: 100% 100%;
  border: 1px solid yellow;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-over-400 {
  width: 65%;
  height: 90%;
  border: 1px solid yellow;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.heading-over-400 {
  color: orangered;
}
.heading-over-inner {
  color: white !important;
}

#one-two {
  height: 75vh;
  border-radius: 1px solid green !important;
}
.blog-section {
  border: 1px solid rgb(210, 4, 247);
}
.blog-inner-section {
  height: 100%;
  display: flex;
  justify-content: center;
}
.single-blog-img {
  width: 100%;
  height: 70%;
  border: 1px solid red;
}
.inner-blog {
  height: 80%;
  border: 1px solid rgb(19, 14, 14);
}
.blog-div-1 {
  width: 100%;
  height: 100%;
  border: 1px solid green;
}
.card-img-top {
  width: 100%;
  height: 70%;
  background-image: url("/assets/images/gnf/bespoke/Over_4000_installation_BG.png");
  background-size: 100% 100%;
}
.card {
  height: 80%;
  border: 1px solid black;
}
.blog-1 {
  border: 1px solid green;
}
#contact-section-glass {
  width: 100%;
  height: 70vh;
  border: 1px solid aqua;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}
.btn-dark-ch {
  background-color: #243746 !important;
  font-size: 20px;
  color: white;
  padding: 7px 67px;
}
article.entry.entry-display {
  border: 1px solid #c0c0c029;
}
.text-muted {
  color: #243746 !important;
}
.btn-heading:hover {
  color: #b68150 !important;
}
.btn_hover_ch {
  border: 2px solid green !important;
}
#bespoke-stone {
  width: 100%;
  height: 48vw;
  background-image: url("/assets/images/gnf/bespoke-stone/Kitchen Worktops Main Page.png");
  background-size: 100% 100%;
  /* border: px solid yellow; */
}
#bespoke-stone1 {
  width: 100%;
  height: 48vw;
  background-image: url("/assets/images/gnf_new/glass/MainPage.jpg");
  background-size: 100% 100%;
  /* border: px solid yellow; */
}
/* .inner-stone-bespoke {
  width: 115%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  color: white;
  display: flex;
  justify-content: center;
  align-items: end;
} */
.heading-bespoke-stone {
  color: white !important;
  font-size: 5em;
  font-weight: 700;
}
.span_hero {
  font-size: 23px;
}
.bespoke-inner-stone-text {
  /* width: 40%; */
  height: auto;
  position: relative;
  top: 25%;
  /* left: -19.5%; */
  color: white !important;
}
.btn-heading {
  display: block;
  margin-top: 30px;
}
#one-two-three {
  /* height: 75vh; */
  border-radius: px solid green !important;
}
.contact-btn {
  color: orange;
  background: white;
  font-size: 20px;
  padding: 0px 20px;
}
.btn-online-quote {
  font-weight: bold;
  font-size: 2.5rem;
  text-align: start;
  line-height: 27px;
  padding: 6px 30px;
}
.what-make-hr {
  margin-left: 0;
  width: 10vw;
  height: 1px;
  position: relative;
  left: -6%;
  background-color: rgb(180, 178, 178);
}
h1.HANDICRAFTS_h {
  font-size: 4.5em;
  font-weight: 500;
  color: #243746;
}
.trending-hr {
  width: 10vw;
  height: 1px;
  background-color: rgb(180, 178, 178);
}
.top-trending-hr {
  width: 10% !important;
  border-bottom: 3px solid darkgoldenrod;
}
.templating-trending:hover {
  color: white;
}
#about-stone-us {
  width: 100%;
  height: 90vh;
  /* border: px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-us-quote-text {
  height: 100%;
  width: 50%;
  /* border: px solid rgb(121, 241, 8); */
}
.about_us_stone_inner {
  /* text-align: justify !important; */
  font-size: 2.5rem !important;
  color: #243746 !important;
  padding-right: 70px;
  display: block;
  font-weight: 400;
}
.about-us-stone-image {
  height: 90%;
  width: 50%;
  /* border: px solid black; */
}
.grid-stone-container {
  display: grid;
  grid-column-gap: 10px;
  /* padding: 5px; */
}
.grid-item {
  /* border: px solid black; */
}
.grid-stone-item1 {
  /* border: px solid white; */
  grid-column-start: 1;
  grid-column-end: 1;
}
.grid-stone-item2 {
  /* border: px solid white; */
  grid-column-start: 2;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}
.grid-stone-item3 {
  /* border: px solid white; */
  grid-column-start: 2;
  grid-column-end: 1;
}
.img-stone-1 {
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/gnf/bespoke-stone/Banner image 1.jpg");
  background-size: 100% 100%;
}
.img-stone-2 {
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/gnf_new/quote/Marble_dummy.png");
  background-size: 100% 100%;
}
.img-stone-3 {
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/gnf_new/glass/Who_we_are_Image3.jpg");
  background-size: 100% 100%;
}
#stone-range {
  height: 90vh;
  /* border: px solid green; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.glass-stone-grid-container {
  height: 90%;
  /* width: 90%; */
  display: grid;
  grid-gap: 20px;
  /* border: px solid black; */
}
.item-stone-img4 {
  /* border: px solid orange; */
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke-stone/handicrafts 1.png");
  background-size: 100% 100%;
}
.item-stone-img5 {
  /* border: px solid orange; */
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke-stone/handicrafts 2.png");
  background-size: 100% 100%;
}
.item-stone-img6 {
  /* border: px solid orange; */
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke-stone/handicrafts 3.png");
  background-size: 100% 100%;
}
.item-stone-img7 {
  /* border: px solid orange; */
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke-stone/handicrafts 4.png");
  background-size: 100% 100%;
}
.item-stone-img8 {
  /* border: px solid orange; */
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke-stone/handicrafts 5.jpg");
  background-size: 100% 100%;
}
.item-stone-img9 {
  /* border: px solid orange; */
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke-stone/handicrafts 6.png");
  background-size: 100% 100%;
}
.item-stone-img10 {
  /* border: px solid orange; */
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke-stone/handicrafts 7.jpg");
  background-size: 100% 100%;
}
.item-stone-img11 {
  /* border: px solid orange; */
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke-stone/handicrafts 8.jpg");
  background-size: 100% 100%;
}
#what-makes {
  width: 100%;
  height: 90vh;
  border: 0px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.what-make-grid-container {
  display: grid;
  grid-column-gap: 10px;
  /* padding: 5px; */
  grid-gap: 10px;
}
.item-what-img1 {
  border: 0px solid orange;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  background-color: rgb(9, 112, 153);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.item-what-img2 {
  border: 0px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-color: rgb(9, 112, 153);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.item-what-img3 {
  border: 0px solid orange;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: rgb(9, 112, 153);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.item-what-img4 {
  border: 0px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
  background-color: rgb(9, 112, 153);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
#top-stone-trending {
  background-color: rgb(230, 230, 230);
  justify-content: center;
}
.top-stone-trending-text h1 {
  color: #243746;
  padding: 2em 0;
  font-size: 4.5em;
}
.top-stone-trending-img {
  width: 100%;
  height: 100%;
  display: grid;
  grid-column-gap: 10px;
}
.templating-trending {
  position: absolute;
  bottom: 0%;
  width: 95%;
  height: 15%;
  text-align: center;
  font-size: 3rem;
  color: white;
  background-color: #b68150;
}
.trending-stone-1 {
  position: relative;
  border: 0px solid orange;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke-stone/Trending 1.jpg");
  background-size: 100% 100%;
}
.trending-stone-2 {
  border: 0px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke-stone/Trending 2.jpg");
  background-size: 100% 100%;
}
.trending-stone-3 {
  border: 0px solid orange;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke-stone/Trending 3.jpg");
  background-size: 100% 100%;
}
#make-it-happen-stone {
  width: 100%;
  height: 50vh;
  border: 0px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.happen-stone-img {
  width: 70%;
  height: 80%;
  border: 0px solid black;
  display: grid;
  grid-column-gap: 15px;
}
.happen-stone-img-1 {
  position: relative;
  border: 0px solid orange;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/3.jpg");
  background-size: 100% 100%;
}
.happen-stone-img-2 {
  position: relative;
  border: 0px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/fabrication-2.jpg");
  background-size: 100% 100%;
}
.happen-stone-img-3 {
  position: relative;
  border: 0px solid orange;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/installation.jpg");
  background-size: 100% 100%;
}
#over-stone-7000 {
  background-image: url("/assets/images/gnf/bespoke/Over_4000_installation_BG.png");
  background-size: 100% 100%;
  padding: 5em 0;
}
.inner-over-stone-7000 {
  color: white;
}
.heading-over-stone-7000 {
  color: #b68150;
  font-size: 4.5em;
}
.heading-over-inner {
  color: white !important;
  font-size: 4.5em;
  font-weight: 600;
}
span.lorem-one {
  font-size: 23px !important;
  font-weight: 400;
}
.from-our-blog {
  width: 100%;
  /* height: 65vh; */
  border: 0px solid red;
}
.blog-section {
  border: 0px solid rgb(210, 4, 247);
}
.blog-inner-section {
  height: 100%;
  display: flex;
  justify-content: center;
}
.single-blog-img {
  width: 100%;
  height: 70%;
  border: 0px solid red;
}
.inner-blog {
  height: 80%;
  border: 0px solid rgb(19, 14, 14);
}
.blog-div-1 {
  width: 100%;
  height: 100%;
  border: 0px solid green;
}
.card-img-top {
  width: 100%;
  height: 70%;
  background-image: url("/assets/images/gnf/bespoke/Over_4000_installation_BG.png");
  background-size: 100% 100%;
}
.card {
  height: 80%;
  border: 0px solid black;
}
.blog-1 {
  border: 0px solid green;
}
#contact-section-glass {
  width: 100%;
  height: 80vh;
  border: 0px solid aqua;
}
.about-us-stone-text {
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 90%;
  width: 50%;
  /* border: px solid black; */
}
.about-us-quote-inner {
  font-size: 2rem;
}
.about-us-quote-image {
  height: 100%;
  width: 50%;
  /* border: px solid black; */
}
.grid-quote-container {
  display: grid;
  grid-column-gap: 10px;
  /* padding: 5px; */
}
.grid-item {
  /* border: px solid black; */
}
.grid-quote-item1 {
  /* border: px solid white; */
  grid-column-start: 1;
  grid-column-end: 1;
}
.grid-quote-item2 {
  /* border: px solid white; */
  grid-column-start: 2;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}
.grid-quote-item3 {
  /* border: px solid white; */
  grid-column-start: 2;
  grid-column-end: 1;
}
.img-quote-1 {
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/gnf/quote/1.jpg");
  background-size: 100% 100%;
}
.img-quote-2 {
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/gnf/quote/2.jpg");
  background-size: 100% 100%;
}
.img-quote-3 {
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/gnf/quote/3.jpg");
  background-size: 100% 100%;
}

.glass-quote-grid-container {
  height: 90%;
  width: 90%;
  display: grid;
  grid-gap: 20px;
  /* border: px solid black; */
}
.item-quote-img4 {
  /* border: px solid orange; */
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/quote/4.jpg");
  background-size: 100% 100%;
}
.item-quote-img5 {
  /* border: px solid orange; */
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/quote/5.jpg");
  background-size: 100% 100%;
}
.item-quote-img8 {
  /* border: px solid orange; */
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/quote/6.jpg");
  background-size: 100% 100%;
}
.item-quote-img9 {
  /* border: px solid orange; */
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/quote/7.jpg");
  background-size: 100% 100%;
}
.item-quote-img10 {
  /* border: px solid orange; */
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/quote/8.jpg");
  background-size: 100% 100%;
}
#material-types {
  width: 100%;
  height: 20vh;
  /* border: px solid yellow; */
}
#top-quote-measurements {
  width: 100%;
  height: 115vh;
  /* border: px solid rgb(163, 6, 6); */
}
.top-quote-measurement-text {
  width: 100%;
  height: 100%;
  /* border: px solid black; */
  display: grid;
}
.map-img {
  background-image: url("/assets/images/gnf/quote/map.jpg");
  background-size: 100% 100%;
  /* border: px solid red; */
}
.top-quote-measurement-form {
  justify-content: center;
  align-items: center;
}
#measuremnt-content {
  width: 100%;
  height: 87%;
  /* border: px solid aqua; */
}
.section-b-space {
  /* width: 70%; */
  height: 100%;
  display: flex;
  justify-content: space-evenly;
}
.our-selected-bttn {
  background-color: #b68150;
  color: white;
  border: none;
  font-size: 2.5rem;
  font-weight: bold;
}
.contact-map-selected {
  padding: 20px;
}
.contact-map {
  border: none !important;
}
#quote-more-one {
  background-color: #dee2e6;
}
.quote-form-one {
  width: 65%;
  height: 90%;
  /* border: px solid yellow; */
  color: white;
}
.heading-quote {
  width: 80%;
  position: relative;
  left: 6%;
  top: 5%;
  font-size: 3.5rem;
  text-align: center;
  /* border: px solid yellow; */
}
.form-inner {
}

.send-calculate {
  font-size: 2.5rem;
  font-weight: bold;
}
.btn-calcu {
  background: rgb(183, 130, 80) !important;
  color: white;
}
.btn-calcu:hover {
  background: rgb(183, 130, 80) !important;
  color: white;
}
#contact-section-glass-form {
  width: 100%;
  height: 80%;
  /* border: px solid rgb(0, 255, 136); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.select-area {
  width: 100%;
  /* height: 30%; */
  /* border: px solid yellow; */
}
.select-text {
  width: 100%;
  height: 70%;
  /* border: px solid black; */
  display: flex;
}
.contact-inner-map {
  height: 100%;
  /* border: px solid red; */
}
.select-text-1 {
  width: 51%;
  height: 100%;
  /* border: px solid orange; */
}
.select-text-2 {
  width: 49%;
  height: 100%;
  /* border: px solid orange; */
}
.select-text-1 > ul {
  font-size: 1.5rem;
  line-height: 50px;
}
.select-text-1 > ul > li > a {
  color: black;
}
.select-text-2 > ul > li > a {
  color: black;
}
.select-text-2 > ul {
  font-size: 1.5rem;
  line-height: 50px;
}
.fill-head {
  width: 100%;
  position: relative;
  left: 10%;
  top: 2%;
  text-align: start;
  color: white !important;
}
.form-span {
  width: 100%;
  position: relative;
  left: 10%;
  text-align: start;
}
.contact-inner-form-map {
  width: 80%;
  height: 85%;
  /* border: px solid rgb(13, 238, 13); */
}
.contact-map-selected {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #243746 !important;
  /* border: px solid rgba(241, 20, 0, 0.678); */
}

.select-text-3 > ul {
  font-size: 16px;
  line-height: 49px;
  font-weight: 500;
}
.select-text-3 > ul > li > a {
  color: #243746;
}
.select-text-3 ul li {
  list-style: initial;
}
.HANDICRAFTS_h2 {
  font-size: 3em;
  font-weight: bold;
  color: #b68150;
  border-bottom: 1px solid #243746;
  padding-bottom: 0.3em;
}
.stone_btn {
  position: absolute;
  z-index: 9;
  bottom: 0;
  left: 0px;
  right: 0;
  background: #ffffffb8;
  width: 100%;
  font-size: 25px;
  color: #243746;
}
.step_h {
  font-size: 30px;
  color: #b68150;
}
.step_1_box_bttom {
  padding: 12px;
  border: 1px solid #e3e3e3;
  margin: 0;
}
.btn_div {
  border: 1px solid #e3e3e3;
}
.btn_div button {
  text-transform: uppercase;
  font-weight: 600;
  color: #243746;
}
.step_1_h {
  text-transform: uppercase;
  font-weight: 600;
  color: #243746;
}
.lebel_step_2 {
  font-size: 20px;
  font-weight: 500;
  color: #243746;
  padding: 2px 60px;
}
.MEASUREMENTS_p {
  font-size: 26px;
  font-weight: 300;
  color: #243746 !important;
}
.form_input_label {
  font-size: 30px;
  font-weight: 500;
  color: silver;
}
.form_input_label1 {
  font-size: 28px;
  font-weight: 500;
  color: silver;
}
.custome_lable {
  color: #243746;
  font-weight: 400;
  font-size: 14px;
}
.note_h {
  font-size: 22px;
  color: #b68150;
}
.about_us_stone_inner3 {
  font-size: 2em;
  font-weight: 600;
  color: #b68150;
  display: block;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .about_us_stone_inner3 {
    text-align: center;
  }
  #bespoke-stone {
    height: 200vw !important;
  }
  #bespoke-stone1 {
    height: 200vw !important;
  }
  .heading-bespoke-stone {
    font-size: 3em;
    text-align: center;
  }
  .span_hero {
    font-size: 18px;
    text-align: center;
    display: block;
  }
  .contact-btn {
    position: relative;
    left: 33%;
  }
  .btn-online-quote {
    position: relative;
    left: 20%;
    text-align: center;
  }
  span.what_make_heading {
    font-size: 22px !important;
    padding: 1em;
  }
  .about_us_stone_inner {
    padding: 1em !important;
    font-size: 18px !important;
    text-align: justify;
  }
  .btn-heading {
    margin: 31px auto 0 !important;
  }
  .style_col_ch {
    margin: 0 1em 2em 0.5em !important;
  }
  .what-make-hr {
    left: 31% !important;
    width: 36vw !important;
  }
  h1.HANDICRAFTS_h {
    font-size: 33px !important;
    text-align: center !important;
  }
  .dark_box {
    margin: 4em 0 0 0;
  }
  .top-stone-trending-text h1 {
    padding: 1em 0.2em 0.7em;
    font-size: 40px;
    text-align: center;
  }
  .img_style_3 {
    height: auto;
    margin-bottom: 2em;
    padding: 1em;
  }
  .img_style_3 .top-stone-trending-img {
    height: auto;
    display: flex;
    flex-direction: column;
    grid-row-gap: 19px;
  }
  .trending-stone-1,
  .trending-stone-2,
  .trending-stone-3 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 2;
    height: 39vh;
  }
  .heading-over-stone-7000 {
    font-size: 2.5em;
  }
  .heading-over-inner {
    font-size: 2.5em;
  }
  span.lorem-one {
    font-size: 13px !important;
  }
  div#over-stone-7000 {
    padding: 3em 1em;
  }
  h3.entry-title {
    font-size: 13px !important;
  }
  .entry-content p {
    font-size: 10px;
  }
  .entry.entry-display .entry-body {
    padding: 0 10px 20px 10px !important;
  }
  .select-text-3 > ul {
    line-height: 31px;
    font-weight: 400;
  }
  .select-text-3 ul li {
    list-style: initial;
    margin-left: 47px;
  }
  .form-span {
    font-size: 10px;
    padding-bottom: 17px;
  }
  .email_ch {
    width: 80% !important;
  }
  .btn_ch_div {
    /* display: flex !important; */
    position: absolute;
    right: 0;
    top: -10px;
  }
  .step_h,
  .mobile_text_center {
    text-align: center !important;
  }
  .mobile_text_center1 {
    font-size: 28px;
    text-align: center !important;
  }
  .step_1_box img {
    width: 100%;
    margin-top: 46px;
  }
  .form_input_label,
  .form_input_label1 {
    font-size: 20px;
  }
  span.about_us_stone_inner3 {
    font-size: 19px;
  }
  .about_us_stone_inner {
    padding: 1em !important;
    font-size: 14px !important;
    text-align: -webkit-center;
  }
  .mbl_center {
    text-align: center !important;
  }
}

