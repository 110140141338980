* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}
.ch_hight {
  height: 100%;
}
.ch_button_handel {
  text-align: center !important;
  width: 40%;
  color: white !important;
}
.ch_button_handel a {
  color: white !important;
}
.HANDICRAFTS_h br {
  display: none !important;
}
#bespoke-main {
  width: 100%;
  height: 90vh;
  background-image: linear-gradient(
      to bottom,
      rgba(3, 44, 71, 0.52),
      rgba(3, 45, 56, 0.73)
    ),
    url("/assets/images/custom_pages/glass-splash-back/bannerimage.jpg");

  background-size: 100% 100%;
  border: 1px solid yellow;
}
/* .inner-bespoke {
  width: 115%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  color: white;
  display: flex;
  justify-content: center;
  align-items: end;
} */
.bespoke-inner-glass-text {
  height: 50%;
  width: 70%;
  border: 1px solid rgb(0, 0, 0);
  position: relative;
  top: 17%;
  left: 5%;
  color: white !important;
}
.bespoke-inner-text {
  width: 58%;
  height: 80%;
  border: 1px solid red;
  color: white !important;
}
#about-us {
  width: 100%;
  height: 90vh;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fill-head {
  text-align: start !important;
  color: white !important;
}
.contact-map-selected {
  text-align: start;
}
.about-us-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  height: 80%;
  width: 50%;
  /* border: 1px solid black; */
}
.what-make-para {
  width: 70%;
  height: 70%;
  border: 1px solid black;
}
.glass-para {
  width: 90%;
  border: 1px solid black;
}
.about-us-image {
  height: 80%;
  width: 50%;
  /* border: 1px solid black; */
}
.grid-container {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  /* padding: 5px; */
}
.grid-item {
  border: 1px solid black;
}
.grid-item1 {
  border: 1px solid white;
  grid-column-start: 1;
  grid-column-end: 1;
  background-image: url("/assets/images/gnf/Who_we_are_Image1.png");
  background-size: 100% 100%;
}
.grid-item2 {
  border: 1px solid white;
  grid-column-start: 2;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  background-image: url("/assets/images/gnf/Who_we_are_Image3.png");
  background-size: 100% 100%;
}
.grid-item3 {
  border: 1px solid white;
  grid-column-start: 2;
  grid-column-end: 1;
  background-image: url("/assets/images/gnf/Who_we_are_Image2.png");
  background-size: 100% 100%;
}

#glass-range {
  height: 75vh;
  border: 1px solid green;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.glass-grid-container {
  height: 90%;
  width: 90%;
  display: grid;
  grid-gap: 10px;
  border: 1px solid black;
}
.item-img1 {
  border: 1px solid orange;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_plain.png");
  background-size: 100% 100%;
}
.item-img2 {
  border: 1px solid orange;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_crackled_glass.png");
  background-size: 100% 100%;
}
.item-img3 {
  border: 1px solid orange;
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_digital_print.png");
  background-size: 100% 100%;
}
.item-img4 {
  border: 1px solid orange;
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_double_layer.png");
  background-size: 100% 100%;
}
.item-img5 {
  border: 1px solid orange;
  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_mettalic_effect.png");
  background-size: 100% 100%;
}
.item-img6 {
  border: 1px solid orange;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_plain_sparkle.png");
  background-size: 100% 100%;
}
.item-img7 {
  border: 1px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_shimmer_effect.png");
  background-size: 100% 100%;
}
.item-img8 {
  border: 1px solid orange;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_special_effect.png");
  background-size: 100% 100%;
}
.item-img9 {
  border: 1px solid orange;
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_satin_glass.png");
  background-size: 100% 100%;
}
.item-img10 {
  border: 1px solid orange;
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_toughened_mirrors.png");
  background-size: 100% 100%;
}
.item-img11 {
  border: 1px solid orange;
  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 2;
  background-image: url("/assets/images/gnf/bespoke/Glass_range_Untoughened_mirrors.png");
  background-size: 100% 100%;
}
#what-makes {
  width: 100%;
  height: 90vh;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.what-make-grid-container {
  display: grid;
  grid-column-gap: 10px;
  /* padding: 5px; */
  grid-gap: 10px;
}
.item-what-img1 {
  border: 1px solid orange;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  background-color: #243746 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.item-what-img2 {
  border: 1px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-color: #243746 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.item-what-img3 {
  border: 1px solid orange;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: #243746 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.item-what-img4 {
  border: 1px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
  background-color: #243746 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.item-what-inner-content {
  color: #b68150;
  /* position: relative;
  left: 10%; */
}
#top-trending {
  width: 100%;
  height: 35vh;
  border: 1px solid black;
  background-color: rgb(230, 230, 230);
  display: flex;
  justify-content: center;
}
.top-trending-text {
  width: 40%;
  height: 100%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-trending-img {
  width: 40%;
  height: 100%;
  border: 1px solid black;
  display: grid;
  grid-column-gap: 10px;
}
.trending-1 {
  border: 1px solid orange;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/GnF Glass Splashback Works - 2.jpg");
  background-size: 100% 100%;
}
.trending-2 {
  border: 1px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/GnF Glass Splashback Works - 3.jpg");
  background-size: 100% 100%;
}
.trending-3 {
  border: 1px solid orange;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/GnF Glass Splashback Works - 4.jpg");
  background-size: 100% 100%;
}
#make-it-happen {
  width: 100%;
  height: 50vh;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.happen-img {
  width: 70%;
  height: 80%;
  border: 1px solid black;
  display: grid;
  grid-column-gap: 10px;
}
.happen-img-1 {
  border: 1px solid orange;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/3.jpg");
  background-size: 100% 100%;
}
.happen-img-2 {
  border: 1px solid orange;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/fabrication-2.jpg");
  background-size: 100% 100%;
}
.happen-img-3 {
  border: 1px solid orange;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  background-image: url("/assets/images/gnf/bespoke/installation.jpg");
  background-size: 100% 100%;
}
#over-400 {
  width: 100%;
  height: 60vh;
  background-image: url("/assets/images/gnf/bespoke/Over_4000_installation_BG.png");
  background-size: 100% 100%;
  border: 1px solid yellow;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-over-400 {
  width: 65%;
  height: 90%;
  border: 1px solid yellow;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.heading-over-400 {
  color: orangered;
}
.heading-over-inner {
  color: white !important;
}

#one-two {
  height: 75vh;
  border-radius: 1px solid green !important;
}
.blog-section {
  border: 1px solid rgb(210, 4, 247);
}
.blog-inner-section {
  height: 100%;
  display: flex;
  justify-content: center;
}
.single-blog-img {
  width: 100%;
  height: 70%;
  border: 1px solid red;
}
.inner-blog {
  height: 80%;
  border: 1px solid rgb(19, 14, 14);
}
.blog-div-1 {
  width: 100%;
  height: 100%;
  border: 1px solid green;
}
.card-img-top {
  width: 100%;
  height: 70%;
  background-image: url("/assets/images/gnf/bespoke/Over_4000_installation_BG.png");
  background-size: 100% 100%;
}
.card {
  height: 80%;
  border: 1px solid black;
}
.blog-1 {
  border: 1px solid green;
}
#contact-section-glass {
  width: 100%;
  height: 70vh;
  border: 1px solid aqua;
}
